define("cc-frontend/components/planner/perspectives/day.css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "date-string": "_date-string_1d23jq",
    "date-string__day-rotation": "_date-string__day-rotation_1d23jq",
    "date-string__month": "_date-string__month_1d23jq",
    "day-wrapper": "_day-wrapper_1d23jq",
    "day-menu-button": "_day-menu-button_1d23jq",
    "day-menu-button__text": "_day-menu-button__text_1d23jq"
  };
  _exports.default = _default;
});