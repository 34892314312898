define("cc-frontend/components/planner/course/create-edit/name-and-more/one-time", ["exports", "@glint/environment-ember-loose/glimmer-component", "cc-frontend/utils/course/times"], function (_exports, _glimmerComponent, _times) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div local-class="course-time-toggle-date-input-group">
    <div local-class="toggle-date-group">
      {{!-- <div
        local-class="toggle-button"
        {{on "click" (fn @toggleIsOn this.courseDate this.isOn)}}
        data-test-course-edit-is-on={{@rotation.id}}
      >
        {{#if this.isOn}}
          <div local-class="checkmark-on">
            <Icons::CheckmarkOn />
          </div>
        {{else}}
          <div local-class="checkmark-off">
            <Icons::CheckmarkOff />
          </div>
        {{/if}}
      </div> --}}
  
      <div local-class="time-date">
        {{@rotation.title}}
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-justify-end">
      {{#if this.isOn}}
        <DS::SimpleContentEditable
          @value={{this.timeFormatted}}
          @isEditable={{includes @course.meta.permissions "can-edit-plan"}}
          local-class="course-one-time-input"
          class="tw-mr-2"
          @placeholder="Start - End"
          @onFinishedEditing={{fn @setTime this.courseDate}}
          data-test-course-edit-one-time={{@rotation.id}}
        />
      {{/if}}
      <DS::Toggle
        class={{local-class "toggle"}}
        data-test-course-edit-is-on={{@rotation.id}}
        @isOn={{this.isOn}}
        @color={{@course.attributes.color}}
        @onToggle={{fn @toggleIsOn this.courseDate this.isOn}}
      />
    </div>
  </div>
  */
  {
    "id": "QYlJkg2v",
    "block": "[[[10,0],[15,0,[29,[[28,[37,0],[\"course-time-toggle-date-input-group\"],[[\"from\"],[\"cc-frontend/components/planner/course/create-edit/name-and-more/one-time.css\"]]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[28,[37,0],[\"toggle-date-group\"],[[\"from\"],[\"cc-frontend/components/planner/course/create-edit/name-and-more/one-time.css\"]]]]]],[12],[1,\"\\n\"],[1,\"\\n    \"],[10,0],[15,0,[29,[[28,[37,0],[\"time-date\"],[[\"from\"],[\"cc-frontend/components/planner/course/create-edit/name-and-more/one-time.css\"]]]]]],[12],[1,\"\\n      \"],[1,[30,1,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"tw-flex tw-flex-row tw-justify-end\"],[12],[1,\"\\n\"],[41,[30,0,[\"isOn\"]],[[[1,\"      \"],[8,[39,2],[[16,0,[29,[\"tw-mr-2 \",[28,[37,0],[\"course-one-time-input\"],[[\"from\"],[\"cc-frontend/components/planner/course/create-edit/name-and-more/one-time.css\"]]]]]],[16,\"data-test-course-edit-one-time\",[30,1,[\"id\"]]]],[[\"@value\",\"@isEditable\",\"@placeholder\",\"@onFinishedEditing\"],[[30,0,[\"timeFormatted\"]],[28,[37,3],[[30,2,[\"meta\",\"permissions\"]],\"can-edit-plan\"],null],\"Start - End\",[28,[37,4],[[30,3],[30,0,[\"courseDate\"]]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,5],[[16,0,[28,[37,0],[\"toggle\"],[[\"from\"],[\"cc-frontend/components/planner/course/create-edit/name-and-more/one-time.css\"]]]],[16,\"data-test-course-edit-is-on\",[30,1,[\"id\"]]]],[[\"@isOn\",\"@color\",\"@onToggle\"],[[30,0,[\"isOn\"]],[30,2,[\"attributes\",\"color\"]],[28,[37,4],[[30,4],[30,0,[\"courseDate\"]],[30,0,[\"isOn\"]]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@rotation\",\"@course\",\"@setTime\",\"@toggleIsOn\"],false,[\"local-class\",\"if\",\"d-s/simple-content-editable\",\"includes\",\"fn\",\"d-s/toggle\"]]",
    "moduleName": "cc-frontend/components/planner/course/create-edit/name-and-more/one-time.hbs",
    "isStrictMode": false
  });

  let OneTimeComponent = (_dec = Ember.inject.service, _dec2 = Ember.computed("args.courseDateDefaults.[]", "args.rotation.id"), _dec3 = Ember.computed("courseDate.attributes.time"), _dec4 = Ember.computed("args.course.attributes.calendar.version", "courseDate.id"), (_class = class OneTimeComponent extends _glimmerComponent.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "courseCalendarEditor", _descriptor, this);
    }

    get courseDate() {
      const ans = this.args.courseDateDefaults.find(date => date.attributes.rotationId === this.args.rotation.id);

      if (!ans) {
        throw new Error(`CourseDateDefault not found for rotation ${this.args.rotation.id} (${this.args.rotation.title})`);
      }

      return ans;
    }

    get timeFormatted() {
      return (0, _times.formatCourseTime)(this.courseDate.attributes.time);
    }

    get isOn() {
      return !this.args.course.attributes.calendar.datesOff.includes(this.courseDate.id);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "courseCalendarEditor", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "courseDate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "courseDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "timeFormatted", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "timeFormatted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isOn", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "isOn"), _class.prototype)), _class));
  _exports.default = OneTimeComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OneTimeComponent);
});