define("cc-frontend/services/callbacks/action/analytics", ["exports", "@sentry/browser", "lodash-es", "cc-frontend/app"], function (_exports, Sentry, _lodashEs, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  _exports.track = track;

  function _default(actionStream) {
    actionStream.onValue(([_type, _payload, actionDoc]) => {
      var _actionDoc$attributes;

      if (actionDoc.attributes.narrative === null) return;
      let humanizedContext = (0, _lodashEs.reduce)(actionDoc.attributes.narrative.context, (acc, v, k) => {
        return (0, _lodashEs.assign)(acc, {
          [(0, _lodashEs.map)((0, _lodashEs.words)(k), _lodashEs.capitalize).join(" ")]: v
        });
      }, {});
      let title = actionDoc.attributes.narrative.titleForAnalytics;
      let sessionUrl = (0, _app.generateFullStorySessionUrl)(true);
      let context = (0, _lodashEs.assign)({}, humanizedContext, {
        Description: Ember.get(actionDoc.attributes.narrative, "description"),
        "Action Id": actionDoc.id,
        "Session Id": actionDoc.relationships.session.data.id,
        // "Sequence Number":           actionDoc.relationships.originatingUser.data.id,
        "Seconds Since Last Action": actionDoc.attributes.timing.secondsSinceLastClientAction,
        "FullStory Url": sessionUrl,
        URL: typeof window !== "undefined" ? window.location.href : null,
        // We want this not humanized
        group_id: (_actionDoc$attributes = actionDoc.attributes.narrative.context) === null || _actionDoc$attributes === void 0 ? void 0 : _actionDoc$attributes.groupId
      });
      context = (0, _lodashEs.omitBy)(context, _lodashEs.isNil);
      track(title, context); // We don't need to send all our events to plausible -- it's not doing anything useful
      // if (typeof plausible !== "undefined" && window.plausible && title) {
      //   window.plausible(title, context)
      // }

      Sentry.addBreadcrumb({
        message: actionDoc.attributes.name,
        category: "action",
        data: Object.assign({}, humanizedContext, {
          Title: title,
          Description: Ember.get(actionDoc.attributes.narrative, "description"),
          "Action Id": actionDoc.id,
          "Fullstory Url": sessionUrl
        })
      });
    });
  }

  function track(title, context) {
    if (window && window.analytics && title) {
      window.analytics.track(title, context, {
        integrations: {
          // Don't double send to Fullstory
          "Fullstory Cloud Mode (Actions)": false,
          Amplitude: {
            // Let amplitude group sessions
            // https://segment.com/docs/connections/destinations/catalog/amplitude/
            session_id: parseInt(sessionStorage.getItem("CC_SESSION_START_TIME"))
          }
        }
      }); // Send our foo page visits as analytics.page()

      if (title === "Page - Visit") {
        window.analytics.page("App", context["Route Name"], {
          fromPage: context["From Page"],
          fromPagePlacement: context["From Page Placement"]
        });
      }
    }

    if (window && typeof window.sessionRewind !== "undefined") {
      // TODO once sessoinRewind adds support for this.
      // window.sessionRewind.track
      try {
        window.sessionRewind.logEvent(title, context);
      } catch (e) {
        console.error("Error sending event to sessionRewind", e);
      }
    }
  }
});