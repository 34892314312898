define("cc-frontend/mixins/draggable-lessons", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      interact(".js-lesson-interior").draggable({
        autoScroll: true,

        onmove(event) {
          var target = event.target,
              // keep the dragged position in the data-x/data-y attributes
          x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx,
              y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy; // translate the element

          target.style.webkitTransform = target.style.transform = "translate3d(" + x + "px, " + y + "px, 1000px) rotate(7deg) scale(1.05)"; // update the posiion attributes

          target.setAttribute("data-x", x);
          target.setAttribute("data-y", y);
        },

        styleCursor: false,
        onstart: event => {
          Ember.set(this, "dragging.isDragging", true); // v4

          $(".single-lesson__body").css("display", "none"); // v5

          $(".js-lesson-body").css("display", "none"); // v5 -- this is odd, but it makes sure that the lesson doesn't appear behind
          // other lessons later in teh week/month

          $(event.target).parents(".js-draggable-z-index-parent").css({
            "z-index": 999999,
            position: "relative"
          }); // Collapse lessons so scroll works

          let clientY = event.clientY; // $('.single-lesson__body').css('display', 'none')

          $(window).scrollTop($(event.target).offset().top - clientY + 20);
          let {
            top,
            left
          } = $(event.target).offset();
          event.pageY = top;
          var target = event.target;
          var draggableElement = event.target;
          target.classList.add("is-dragging");
          target.classList.add("is-dragging-start");
          window._draggedFromDate = draggableElement.dataset["dateString"];
          window._draggedFromCourseId = draggableElement.dataset["courseId"];
          window._draggedFromCardStackId = draggableElement.dataset["cardStackId"];
          $("body").addClass("is-dragging");
          Ember.run.later(this, () => {
            target.classList.remove("is-dragging-start");
          }, 100);
        },
        onend: event => {
          Ember.set(this, "dragging.isDragging", false);
          var target = event.target;
          target.classList.remove("is-dragging");
          target.classList.remove("can-drop");
          target.style.transform = "none";
          target.setAttribute("data-x", 0);
          target.setAttribute("data-y", 0);
          $("body").removeClass("is-dragging"); //v5

          $(".js-lesson-body").css("display", "block");
        }
      }).styleCursor(false).allowFrom(".js-lesson-move-pad.js-can-drag-lesson");
      interact(".js-lesson-exterior").dropzone({
        // ondragenter(event){
        //   var draggableElement = event.relatedTarget,
        //   dropzoneElement = event.target;
        //
        //   // feedback the possibility of a drop
        //   if (draggableElement.dataset["cardStackId"] === dropzoneElement.dataset["cardStackId"]) return;
        //   dropzoneElement.classList.add('drop-target');
        //   draggableElement.classList.add('can-drop');
        // },
        ondragleave(event) {
          var draggableElement = event.relatedTarget,
              dropzoneElement = event.target;
          dropzoneElement.classList.remove("drop-target");
          draggableElement.classList.remove("can-drop");
        },

        styleCursor: false,
        ondropdeactivate: function (event) {
          // remove active dropzone feedback
          event.target.classList.remove("drop-target");
        },
        ondragenter: function (event) {
          var draggableElement = event.relatedTarget;
          var dropzoneElement = event.target; // feedback the possibility of a drop

          if (draggableElement.dataset["cardStackId"] === dropzoneElement.dataset["cardStackId"]) return;
          if (dropzoneElement.dataset["isOff"] === "true") return;
          dropzoneElement.classList.add("drop-target");
          draggableElement.classList.add("can-drop");
          return;
        },
        ondrop: function (event) {
          var draggableElement = event.relatedTarget;
          var dropzoneElement = event.target; // var x = (parseFloat(draggableElement.getAttribute('data-x')) || 0);
          // var y = (parseFloat(draggableElement.getAttribute('data-y')) || 0);

          draggableElement.style.visibility = "hidden"; // v5 -- this is odd, but it makes sure that the lesson doesn't appear behind
          // other lessons later in teh week/month

          $(draggableElement).parents(".js-draggable-z-index-parent").css({
            "z-index": 0,
            position: "static"
          });
          let draggedFromDate = draggableElement.dataset["dateString"];
          let draggedFromCourseId = draggableElement.dataset["courseId"];
          let draggedFromCardStackId = draggableElement.dataset["cardStackId"];
          let droppedOnDate = dropzoneElement.dataset["dateString"];
          let droppedOnCourseId = dropzoneElement.dataset["courseId"];
          let fromCourse = this.store.findInMemory("course", draggedFromCourseId);
          let toCourse = this.store.findInMemory("course", droppedOnCourseId);

          if ((0, _lodash.isNil)(toCourse) && (0, _lodash.isNil)(fromCourse)) {
            console.log(`The to course and from course were both nil.`);
            return;
          }

          let planbook = (0, _lodash.isNil)(toCourse) ? this.store.findInMemory("planbook", fromCourse.relationships.planbook.data.id) : this.store.findInMemory("planbook", toCourse.relationships.planbook.data.id);
          let rotationCalendar = this.store.findInMemory("rotation-calendar", planbook.relationships.rotationCalendar.data.id);
          $(".week-grid").css({
            opacity: 0
          }); // We delay for the transition to run

          Ember.run.later(() => {
            if (draggedFromCourseId === droppedOnCourseId) {
              this.store.dispatch("MOVE_LESSON_TO_DATE", {
                cardStackId: draggedFromCardStackId,
                fromDate: draggedFromDate,
                toDate: droppedOnDate,
                course: fromCourse,
                planbook: planbook,
                rotationCalendar: rotationCalendar
              });
            } else {
              this.store.dispatch("MOVE_LESSON_TO_DATE_ON_DIFFERENT_COURSE", {
                cardStackId: draggedFromCardStackId,
                fromDate: draggedFromDate,
                toDate: droppedOnDate,
                fromCourse: fromCourse,
                toCourse: toCourse,
                planbook: planbook,
                rotationCalendar: rotationCalendar
              });
            } // We wait for the animation to run


            Ember.run.scheduleOnce("afterRender", () => {
              // v4
              $(".single-lesson__body").css("display", "block"); //v5

              $(".js-lesson-body").css("display", "block");
              $(".week-grid").css({
                opacity: 1
              });
              draggableElement.style.visibility = "visible";
              Ember.run.later(this, () => {
                let offset = $(`.js-lesson-interior[data-card-stack-id=${draggedFromCardStackId}]`).offset(); // if (offset) $(window).scrollTop(offset.top - 100)

                if (offset) $("body").animate({
                  scrollTop: offset.top - 100
                }, 500);
              }, 300);
            });
          }, 300);
        }.bind(this)
      }).styleCursor(false).allowFrom(".js-lesson-move-pad.js-can-drag-lesson");
    }

  });

  _exports.default = _default;
});