define("cc-frontend/components/planner/card-stacks/cards", ["exports", "@ember-decorators/component", "ember-sortable/mixins/sortable-node", "lodash-es"], function (_exports, _component, _sortableNode, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="lesson-sections test-lesson-cards" data-test-lesson-sections={{@courseId}}>
    {{#each @sortedCards key="id" as |section index|}}
      <Planner::CardStacks::Cards::Card
        @beginMove={{this.beginMove}}
        @cardIndex={{index}}
        @cardStackId={{@cardStackId}}
        @componentId={{concat "lesson-section-" section.id "-" @isFullScreen}}
        @courseId={{@courseId}}
        @courseStandardSetIds={{@courseStandardSetIds}}
        @hideMoveButton={{@hideMoveButton}}
        @model={{section}}
        @modelsNeededForFirstEdit={{@modelsNeededForFirstEdit}}
        @onMove={{fn this.onMove @cardStackId}}
        @openGoogleClassroomPostSidebar={{this.openGoogleClassroomPostSidebar}}
        @permissions={{@permissions}}
        @planbookId={{@planbookId}}
        @planbookRecentStandardSetIds={{@planbookRecentStandardSetIds}}
        @sortableModel={{section}}
        @sortableModelContainer={{@cardStackId}}
        @sortableParent={{this}}
        @usedAs={{@usedAs}}
      />
    {{/each}}
  </div>
  */
  {
    "id": "peoAt2cY",
    "block": "[[[10,0],[14,0,\"lesson-sections test-lesson-cards\"],[15,\"data-test-lesson-sections\",[30,1]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],\"id\",[[[1,\"    \"],[8,[39,2],null,[[\"@beginMove\",\"@cardIndex\",\"@cardStackId\",\"@componentId\",\"@courseId\",\"@courseStandardSetIds\",\"@hideMoveButton\",\"@model\",\"@modelsNeededForFirstEdit\",\"@onMove\",\"@openGoogleClassroomPostSidebar\",\"@permissions\",\"@planbookId\",\"@planbookRecentStandardSetIds\",\"@sortableModel\",\"@sortableModelContainer\",\"@sortableParent\",\"@usedAs\"],[[30,0,[\"beginMove\"]],[30,4],[30,5],[28,[37,3],[\"lesson-section-\",[30,3,[\"id\"]],\"-\",[30,6]],null],[30,1],[30,7],[30,8],[30,3],[30,9],[28,[37,4],[[30,0,[\"onMove\"]],[30,5]],null],[30,0,[\"openGoogleClassroomPostSidebar\"]],[30,10],[30,11],[30,12],[30,3],[30,5],[30,0],[30,13]]],null],[1,\"\\n\"]],[3,4]],null],[13]],[\"@courseId\",\"@sortedCards\",\"section\",\"index\",\"@cardStackId\",\"@isFullScreen\",\"@courseStandardSetIds\",\"@hideMoveButton\",\"@modelsNeededForFirstEdit\",\"@permissions\",\"@planbookId\",\"@planbookRecentStandardSetIds\",\"@usedAs\"],false,[\"each\",\"-track-array\",\"planner/card-stacks/cards/card\",\"concat\",\"fn\"]]",
    "moduleName": "cc-frontend/components/planner/card-stacks/cards.hbs",
    "isStrictMode": false
  });

  let // @ts-ignore
  Cards = (_dec = (0, _component.classNames)("lesson-sections-sortable"), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec(_class = (_class2 = class Cards extends Ember.Component.extend(_sortableNode.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "usedAs", void 0);

      _defineProperty(this, "cardStackId", void 0);

      _defineProperty(this, "modelsNeededForFirstEdit", void 0);

      _defineProperty(this, "permissions", void 0);

      _defineProperty(this, "isFullScreen", void 0);

      _defineProperty(this, "planbookId", void 0);

      _defineProperty(this, "courseId", void 0);

      _defineProperty(this, "dateString", void 0);

      _defineProperty(this, "sortedCards", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "lessonEditor", _descriptor2, this);

      _initializerDefineProperty(this, "modalManager", _descriptor3, this);

      _defineProperty(this, "handle", ".js-move-button");

      _defineProperty(this, "sortableChildren", Ember.A([]));

      _defineProperty(this, "canShowAddCardMenu", false);

      _defineProperty(this, "addCardMenuConstraints", [{
        to: "window",
        pin: ["bottom"]
      }]);
    }

    addSortableChild(child) {
      // Delay so we don't block render
      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.scheduleOnce("afterRender", this, () => {
        // @ts-ignore
        this.sortableChildren.addObject(child);
        this.sortableChildren = (0, _lodashEs.sortBy)(this.sortableChildren, // @ts-ignore
        child => child.model.attributes.position);
      });
    }

    removeSortableChild(child) {
      // @ts-ignore
      this.sortableChildren.removeObject(child);
    }

    canReceiveSortable(_node) {
      console.log("can receive sortable");
      return true;
    }

    highlightMovingSection(movingSection) {
      let id = `#lesson-section-${movingSection.id}`; // The reason we look up the element everytime is because it might not be in the dom at the start
      // of the method

      Ember.run.later(() => {
        // set(movingSection, "justMoved", true)
        $(id).addClass("just-moved");
        Ember.run.later(() => {
          // set(movingSection, "justMoved", false)
          $(id).removeClass("just-moved");
          Ember.run.later(() => {
            // set(movingSection, "justMoved", true)
            $(id).addClass("just-moved");
            Ember.run.later(() => {
              // set(movingSection, "justMoved", false)
              $(id).removeClass("just-moved");
            }, 750);
          }, 750);
        }, 750);
      }, 750);
    }

    beginMove() {
      $(":focus").blur();
      this.sortableChildren = (0, _lodashEs.sortBy)(this.sortableChildren, // @ts-ignore
      child => child.model.attributes.position);
    }

    onMove(oldCardStackId, section, {
      newParent,
      receiverContext,
      position
    }) {
      let newCardStackId = receiverContext ? receiverContext.cardStackId : oldCardStackId;
      this.lessonEditor.moveCard(this.modelsNeededForFirstEdit, section, oldCardStackId, newCardStackId, newParent, receiverContext, position);
      this.highlightMovingSection(section);
    }

    openGoogleClassroomPostSidebar(card) {
      if (card.type === "card-google-classroom" && this.usedAs === "lesson" && (0, _lodashEs.includes)(this.permissions, "can-edit-plan")) {
        if (document && document.activeElement) document.activeElement.blur();
        this.store.find("planbook", this.planbookId).then(planbook => {
          this.modalManager.openModal("planner/modals/google-classroom", {
            cardId: card.id,
            dateString: this.dateString,
            modelsNeededForFirstEdit: this.modelsNeededForFirstEdit,
            cardStackId: this.cardStackId,
            planbook: planbook
          });
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "lessonEditor", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "modalManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "beginMove", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "beginMove"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onMove", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "onMove"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "openGoogleClassroomPostSidebar", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "openGoogleClassroomPostSidebar"), _class2.prototype)), _class2)) || _class);
  _exports.default = Cards;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Cards);
});