define("cc-frontend/services/socket", ["exports", "@sentry/browser", "cc-frontend/lib/object-id-gen", "ember-concurrency", "ember-concurrency-ts", "js-cookie", "lodash", "lodash-es", "tracked-built-ins", "cc-frontend/app", "cc-frontend/config/environment", "cc-frontend/lib/phoenix/index", "compare-versions", "date-fns", "cc-frontend/services/callbacks/action/analytics"], function (_exports, Sentry, _objectIdGen, _emberConcurrency, _emberConcurrencyTs, _jsCookie, _lodash, _lodashEs, _trackedBuiltIns, _app, _environment, _index, _compareVersions, TypedDateFns, _analytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fibonacciBackoff = fibonacciBackoff;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // If it's only been one minute since we blurred, we don't need to check again.
  // This isn't designed for quick switching between tabs. This is designed for coming back
  // to a tab after it's been gone
  const MAX_ALLOWED_BLUR_TIME = 1000 * 120;
  let SocketService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec10 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class = class SocketService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "finder", _descriptor3, this);

      _initializerDefineProperty(this, "rpc", _descriptor4, this);

      _initializerDefineProperty(this, "socketSubscriber", _descriptor5, this);

      _initializerDefineProperty(this, "fastboot", _descriptor6, this);

      _initializerDefineProperty(this, "inAppNotice", _descriptor7, this);

      _initializerDefineProperty(this, "query", _descriptor8, this);

      _defineProperty(this, "failedActionAlertOpen", false);

      _defineProperty(this, "hasSentFailedActions", false);

      _defineProperty(this, "hasEncounteredFatalError", false);

      _defineProperty(this, "showBusySyncingModal", false);

      _defineProperty(this, "isReconnectingToSocket", false);

      _initializerDefineProperty(this, "isOnline", _descriptor9, this);

      _defineProperty(this, "blurOccuredAt", new Date());

      _defineProperty(this, "focusOccuredAt", new Date());

      _defineProperty(this, "isBlurred", false);

      _defineProperty(this, "activeStartedAt", new Date());

      _defineProperty(this, "lastActiveEventAt", new Date());

      _defineProperty(this, "isActive", false);

      _defineProperty(this, "subscriptions", []);

      _defineProperty(this, "querySubscriptions", []);

      _defineProperty(this, "actionCount", 0);

      _defineProperty(this, "actionCountAtFocus", 0);

      _defineProperty(this, "hasExpired", false);

      _defineProperty(this, "userFacingActionCount", 0);

      _defineProperty(this, "lastActionId", null);

      _defineProperty(this, "syncedUserFacingActionsCount", 0);

      _defineProperty(this, "actions", []);

      _defineProperty(this, "recentlyPublishedActions", []);

      _defineProperty(this, "socket", null);

      _defineProperty(this, "channel", null);

      _defineProperty(this, "isRedirectingAfterSignIn", false);

      _initializerDefineProperty(this, "windowIsUnloading", _descriptor10, this);

      _defineProperty(this, "socketErrorCount", 0);

      _defineProperty(this, "socketOnCloseRef", null);

      _defineProperty(this, "socketOnErrorRef", null);

      _defineProperty(this, "socketOnOpenRef", null);

      _defineProperty(this, "lastTransportUsed", null);

      _defineProperty(this, "channelErrorCount", 0);

      _defineProperty(this, "isSyncing", false);

      _defineProperty(this, "_notifyIsSyncingTimer", null);

      _defineProperty(this, "_pollActionsTimer", null);
    }

    initiate() {
      if (this.fastboot.isFastBoot) return;
      if (_environment.default.environment === "test") return;
      if (this.session.isInStoryBook) return;

      this._pollActions();

      this.socketSubscriber.heartbeatLoop();
      (0, _emberConcurrencyTs.taskFor)(this._notifyIsSyncing).perform(); // Add Idle timer

      if (document) {
        let events = ["change", "keydown", "mousedown", "mouseup", "mousemove", "orientationchange", "scroll", "touchend", "touchmove", "touchstart"];
        (0, _lodashEs.forEach)(events, eventName => {
          document.addEventListener(eventName, () => {
            if (this.isActive === false) {
              this.handleActiveStart();
            }

            this.lastActiveEventAt = new Date();
          });
        });
        document.addEventListener("visibilitychange", () => {
          if (document.hidden) {
            this.handleActiveEnd();
          } else {
            this.handleActiveStart();
          }
        });
      } // Set an interval to check for activity.


      setInterval(() => {
        let diffInSeconds = TypedDateFns.differenceInSeconds(new Date(), this.activeStartedAt);

        if (this.isActive === true) {
          if (diffInSeconds > 15) {
            this.handleActiveEnd();
          }
        } else {
          this.handleActiveStart();
        }
      }, 15000);

      if (document.hasFocus()) {
        this.handleActiveStart();
      }

      if (window && window.addEventListener) {
        window.addEventListener("beforeunload", e => {
          this.windowIsUnloading = true; // We set this so that we know not to check for unsynced actions

          if (this.isRedirectingAfterSignIn === true) return; // no support for custom messages
          // https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup?answertab=active#tab-top

          let lengthOfActions = this.actions.length;

          if (lengthOfActions > 0 && Ember.get(this, "hasEncounteredFatalError") !== true) {
            let confirmationMessage = `If you exit now, you'll lose work. You have ${lengthOfActions} left to sync. Try closing the browser after another few seconds.`;
            e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+

            return confirmationMessage; // Gecko, WebKit, Chrome <34
          } else {
            return false;
          }
        });
        window.addEventListener("focus", _e => {
          if (this.hasExpired === true) this.reloadPage();
          this.handleActiveStart();
          this.isBlurred = false;
          let timeSinceBlur = new Date().valueOf() - this.blurOccuredAt.valueOf();

          if (timeSinceBlur > MAX_ALLOWED_BLUR_TIME) {
            (0, _emberConcurrencyTs.taskFor)(this.store.checkStaleness).perform();
          }

          this.socketSubscriber.heartbeat();
        });
        window.addEventListener("blur", _e => {
          this.isBlurred = true;
          this.blurOccuredAt = new Date();
          this.handleActiveEnd();
          this.socketSubscriber.heartbeat();
        });
      }
    }

    handleActiveStart() {
      if (document.hasFocus() === false) return;
      if (this.isActive === true) return;
      this.isActive = true;
      this.actionCountAtFocus = this.actionCount;
      this.activeStartedAt = new Date();
      console.log(`Active Start: ${this.activeStartedAt}`);
    }

    handleActiveEnd() {
      if (this.isActive === false) return;
      this.isActive = false;
      let sessionTimeInSeconds = TypedDateFns.differenceInSeconds(this.lastActiveEventAt, this.activeStartedAt);
      console.log(`Active End: ${sessionTimeInSeconds} seconds at ${this.lastActiveEventAt}`);

      if (sessionTimeInSeconds > 0) {
        this.channel.push("new-session-moment", {
          startedAt: TypedDateFns.formatISO(this.activeStartedAt),
          endedAt: TypedDateFns.formatISO(this.lastActiveEventAt),
          actionCount: this.actionCount - this.actionCountAtFocus,
          seconds: sessionTimeInSeconds,
          fullStorySessionUrl: (0, _app.generateFullStorySessionUrl)()
        }); // .receive("ok", () => console.log("Sent session moment."))
      }
    }

    connect(shouldLongPoll) {
      if (_environment.default.environment === "test") return;
      if (this.session.isInStoryBook) return;
      if (this.fastboot.isFastBoot) return;

      try {
        if (this.socket) return;

        this._createSocket(shouldLongPoll);

        console.log(`Starting connection to socket over ${shouldLongPoll ? "long polling" : "websocket"}...`);
        this.socket.connect();

        this._connectToChannel();
      } catch (e) {
        console.log("Error connecting to socket", e);
        (0, _emberConcurrencyTs.taskFor)(this.reconnectToSocket).perform("LONGPOLL");
      }

      return this.socket;
    }

    _createSocket(shouldLongPoll) {
      let transport = shouldLongPoll ? _index.LongPoll : null;
      this.lastTransportUsed = shouldLongPoll ? "LONGPOLL" : "WEBSOCKET";
      this.socket = new _index.Socket(_environment.default.SOCKET_URL, {
        transport: transport,
        logger: (kind, msg, data) => {
          if (kind === "transport" || kind === "channel") {
            console.log(`${kind}: ${msg}`, data);
          }
        },
        params: {
          id: this.session.id,
          clientVersion: _environment.default.CLIENT_VERSION,
          userAgent: navigator.userAgent,
          browserId: this.session.browserId
        },
        longpollerTimeout: 20000,
        reconnectAfterMs: function (tries) {
          return [1000, 5000, 10000, 20000][tries - 1] || 30000;
        }
      }); // We should try a websocket after a while

      if (shouldLongPoll) {
        Ember.run.later(() => {
          (0, _emberConcurrencyTs.taskFor)(this.reconnectToSocket).perform("WEBSOCKET");
          return;
        }, 60000 * 60);
      }
      /**
       * This is called twice if we're long polling. Why? Great question and I spent a few hours o
       * of my life figuring it out. Hours that will haunt me. forever.
       *
       * The first callback is from the xhr on ready state change callback
       * The second callback is from the xhrRequest callback.
       *
       * Both of these are in phoenix.js
       *
       * The implication is that the socketErrorCount will be double counted if we're using
       * long polling. This isn't a problem per se, but it is very odd and surprising.
       */


      this.socketOnErrorRef = this.socket.onError(reason => {
        this.socketErrorCount++; // Just give up

        if (this.socketErrorCount > 200) this.reloadPage(); // This means that short blips won't trigger the offline notice -- only
        // if we've failed 6 times which at the retry interval means after 23 seconds.

        if (this.socketErrorCount > 5) Ember.set(this, "isOnline", false); // Try long polling if it doesn't work. We try it before we set isOnline to false
        // so we can hopefully avoid those messages for people by trying all the connection
        // options first

        if (this.socketErrorCount > 3 && this.lastTransportUsed === "WEBSOCKET") {
          (0, _emberConcurrencyTs.taskFor)(this.reconnectToSocket).perform("LONGPOLL");
        }
      });
      this.socketOnCloseRef = this.socket.onClose(reason => {
        this.socketErrorCount++;
        console.log(`Socket closed. Is Online: ${window.navigator.onLine}. Error Count: ${this.socketErrorCount}. Last Transport: ${this.lastTransportUsed}`, reason); // Don't let it be closed cleanly -- it should always reconnect.

        if ((reason === null || reason === void 0 ? void 0 : reason.wasClean) === true) {// This is handled by the socket subscriber code.
          // taskFor(this.reconnectToSocket).perform("WEBSOCKET")
        } // This means that short blips won't trigger the offline notice -- only
        // if we've failed 6 times which at the retry interval means after 23 seconds.


        if (this.socketErrorCount > 5) {
          Ember.set(this, "isOnline", this.socket.isConnected());
        } // If we're not able to connect over sockets, try longpolling


        if (this.socketErrorCount > 3 && this.lastTransportUsed === "WEBSOCKET") {
          (0, _emberConcurrencyTs.taskFor)(this.reconnectToSocket).perform("LONGPOLL");
        }
      });
      this.socketOnOpenRef = this.socket.onOpen(() => {
        // We reset this when we've connected successfully
        this.socketErrorCount = 0; // Let the user know we're online

        this.isOnline = false;
        console.log("Connected to socket"); // Check to make sure we don't have any stale documents that came in
        // while we were offline or that hit the server before the server
        // had our subscriptions

        Ember.run.later(() => {
          (0, _emberConcurrencyTs.taskFor)(this.store.checkStaleness).perform({
            ignoreTimeSinceFetch: true
          }); // Make it a bit random so we don't completely swamp
          // the server when ever it restarts
        }, Math.floor(Math.random() * 15000));
      });
    }

    get underlyingSocketIsConncted() {
      return this.socket.isConnected();
    }
    /**
      Works to reconnect.
      - Leaves the channel
      - Disconnects from the socket
      - Logs
      - Tries to connect again
    */


    *reconnectToSocket(transport) {
      // Make sure that if we have any errors, we don't lock ourselves out of reconnecting.
      // We do this by wrapping this in a try/catch and setting the guard (isReconnectingToSocket) to false
      // so we can try to hit this function again without any issues
      if (this.channel) this.channel.leave(); // Reset the error count since we're leaving the channel

      this.channelErrorCount = 0;
      this.socket.off([this.socketOnCloseRef, this.socketOnErrorRef, this.socketOnOpenRef]);
      this.socket.disconnect();
      this.socket = null;

      if (transport === "LONGPOLL") {
        console.log("Downgrade to longpolling");
        if (window && window.analytics) window.analytics.track("Socket - downgrading to long polling");
      } else if (transport === "WEBSOCKET") {
        console.log("Try connecting with websockets");
      }

      this.connect(transport === "LONGPOLL");
    }

    reloadPage() {
      // Not sure if this works if the tab is on a background tab, so putting the swal in just in case
      window.location.reload();

      if (window && window.swal) {
        window.swal({
          title: "The connection has grown stale.",
          text: `Refresh the page and we'll make a new connection to the server.`,
          type: "info",
          showCancelButton: false
        }).then(() => {
          window.location.reload();
        }).catch(() => {
          window.location.reload();
        });
      }
    }

    /**
     * The internal logic of the Channel is it will keep reconnecting as long as the socket is open.
     * I was originally going to change this to make sure it reconnects, but that's already taken care of
     */
    _connectToChannel() {
      console.log(`Connecting to channel: sessions:${this.session.id}`);
      this.channel = this.socket.channel(`sessions:${this.session.id}`, {
        token: this.session.token,
        subscriptions: this.subscriptions,
        querySubscriptions: this.querySubscriptions,
        userAgent: navigator.userAgent,
        clientVersion: _environment.default.CLIENT_VERSION
      });
      this.channel.onError(reason => {
        this._onChannelError("ERROR", reason);
      }); // From the docs:
      // `onClose` hooks are invoked only in two cases. 1) the channel explicitly
      // closed on the server, or 2). The client explicitly closed, by calling
      // Becuase of that, we don't call onChannelError
      // But we do increment the channel Error count

      this.channel.onClose(reason => {
        this.channelErrorCount++;
        console.log("Channel Closed Gracefully", reason);
      });
      this.channel.join().receive("ok", resp => {
        console.log(`Connected to channel. Syncing server "${resp.hostname}" is running ${resp.serverVersion} on release ${resp.release}.`); // @ts-ignore

        if ((0, _compareVersions.default)(resp.serverVersion, "5.4.139", ">") && this.session.token) {
          this.refreshToken();
        }

        Ember.set(this, "isOnline", true);
        this.channelErrorCount = 0;
        this.socketSubscriber.heartbeat();
      }).receive("error", resp => {
        this._onChannelError("RECEIVE_ERROR", resp);
      }).receive("timeout", resp => {
        this._onChannelError("TIMEOUT", resp);
      }).receive(e => {
        this._onChannelError("OTHER_ERROR", e);
      });
      this.channel.on("new-model-update", patch => {
        try {
          this.socketSubscriber.onModelUpdate(patch);
        } catch (e) {
          (0, _app.handleError)(e);
        }
      });
      this.channel.on("new-in-app-notice", notice => {
        if (notice.type === "in-app-notice") {
          Ember.get(this, "inAppNotice").newNotice(notice);
        } else {
          throw Error(`notice.type "${notice.type}" is not recognized.`);
        }
      });
      this.channel.on("model-invalidation", invalidation => {
        Ember.get(this, "store").find(invalidation.type, invalidation.id, true);
      });
      this.channel.on("query-invalidation", query => {
        Ember.get(this, "query").invalidate(query.type, query.id);
      });
      this.channel.on("reload-page", () => {
        this.reloadPage();
      });
      this.channel.on("sign-out", () => {
        this.session.signOut();
        window.location.assign("https://www.commoncurriculum.com");
      });
    }

    _onChannelError(errorType, errorOrResponse = null) {
      this.channelErrorCount++;

      if (this.channelErrorCount > 10) {
        Ember.set(this, "isOnline", false); // If the channel keeps erring, what might be happening is the WS is being blocked
        // and nothing is getting through, so, let's try it again with longpolling.

        (0, _emberConcurrencyTs.taskFor)(this.reconnectToSocket).perform("LONGPOLL");
      }

      console.log(`Unable to join user channel: ${errorType}`);
      console.log(errorOrResponse);
    }
    /**
     *****************************************************************************************************
     * Authentication
     *****************************************************************************************************
     */


    authenticateWithToken(token) {
      if (_environment.default.environment === "test") return;
      if (this.fastboot.isFastBoot) return;
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._ensureChannelIsConnected();

        this.channel.push("authenticate-with-token", {
          token: token,
          clientVersion: _environment.default.CLIENT_VERSION,
          userAgent: navigator.userAgent,
          fbc: _jsCookie.default.get("_fbc"),
          fbp: _jsCookie.default.get("_fbp")
        }).receive("ok", resolve).receive("error", reject);
      });
    }

    notifyUpgradeToPro({
      userId: userId
    }) {
      if (_environment.default.environment === "test") return;
      if (this.fastboot.isFastBoot) return;
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._ensureChannelIsConnected();

        this.channel.push("notify-upgrade-to-pro", {
          userId
        }).receive("ok", resolve).receive("error", reject);
      });
    }

    refreshToken() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._ensureChannelIsConnected();

        this.channel.push("refresh-token", {
          token: this.session.token
        }).receive("ok", result => {
          this.session.setToken(result.userId, result.token, this.session.isImpersonating);
          return resolve(result);
        }).receive("error", error => {
          // Sign them out if there's an error
          this.session.signOut();
          window.location.reload();
        });
      });
    }

    pushHeartbeat(cb) {
      this._ensureChannelIsConnected();

      this.channel.push("heartbeat").receive("ok", cb);
    }
    /**
     *****************************************************************************************************
     * Set syncing status
     *****************************************************************************************************
     */


    *_notifyIsSyncing() {
      Ember.set(this, "isSyncing", true);
      yield (0, _emberConcurrency.timeout)(500);

      let failedActionCount = _lodash.default.filter(Ember.get(this, "actions"), action => {
        return new Date().valueOf() - dateFns.parse(action.attributes.timing.clientSentAt).valueOf() > 10000 || action.attributes.sync.status === "failed";
      }).length;

      if (failedActionCount > 0) {
        console.log(`😩 Failed actions: ${failedActionCount}`, Ember.get(this, "actions"));
      }

      if (failedActionCount > 5) {
        (0, _analytics.track)("Show Sync Modal");
        Ember.set(this, "showBusySyncingModal", true);
      } else {
        Ember.set(this, "showBusySyncingModal", false);
      }

      if (_lodash.default.filter(Ember.get(this, "actions"), action => action.attributes.sync.status === "in-flight").length === 0) {
        Ember.set(this, "isSyncing", false);
      }

      this._notifyIsSyncingTimer = Ember.run.later(() => (0, _emberConcurrencyTs.taskFor)(this._notifyIsSyncing).perform(), 2500);
    }

    willDestroy() {
      if (this._notifyIsSyncingTimer) Ember.run.cancel(this._notifyIsSyncingTimer);
      if (this._pollActionsTimer) Ember.run.cancel(this._pollActionsTimer);
      super.willDestroy();
    }
    /**
     *****************************************************************************************************
     * Subscribing
     *****************************************************************************************************
     */

    /**
     * This should be moved to another service. Components might need to subscribe
     * such as a course date subscribing to the lessonTemplateId
     */


    subscribe(modelName, id) {
      // For now, skip this in test. If we can find an easy way to mock the WS
      // we'd want to do that.
      if (modelName === null) return;
      if (id === null || id === undefined) return;
      if (_environment.default.environment === "test") return;
      if (this.fastboot.isFastBoot) return;
      if (_lodash.default.includes(this.subscriptions, `${modelName}:${id}`)) return;
      if (this.channel === undefined) return;
      let subscriptionIdentifier = `${modelName}:${id}`;

      this._ensureChannelIsConnected();

      this.channel.push("new-subscription", subscriptionIdentifier, 1440000).receive("ok", () => this.subscriptions.push(subscriptionIdentifier)).receive("error", reasons => {
        _lodash.default.pull(this.subscriptions, subscriptionIdentifier);

        console.log("Model Subscription Failed: Error ", reasons);
      }).receive("timeout", () => {
        _lodash.default.pull(this.subscriptions, subscriptionIdentifier);

        console.log("Model Subscription Failed: Networking issue...");
      });
    }

    subscribeToQuery(type, id) {
      if (this.fastboot.isFastBoot) return;
      this.querySubscriptions.push(`${type}:${id}`);

      this._ensureChannelIsConnected();

      this.channel.push("subscribe-to-query", `${type}:${id}`, 1444000).receive("ok", () => true).receive("error", reasons => {
        _lodash.default.pull(this.querySubscriptions[`${type}:${id}`]);

        console.log("Query Subscription Failed: Error", reasons);
      }).receive("timeout", () => {
        _lodash.default.pull(this.querySubscriptions[`${type}:${id}`]);

        console.log("Query Subscription Failed: Networking issue...");
      });
    }

    checkStaleness(type, id) {
      if (_environment.default.environment === "test") return;
      if (this.fastboot.isFastBoot) return;
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._ensureChannelIsConnected();

        this.channel.push("check-document-revision", {
          type,
          id
        }).receive("ok", resolve).receive("error", reject);
      });
    }
    /**
     *****************************************************************************************************
     * Publishing
     *****************************************************************************************************
     */

    /**
     * For sending actions back to the server
     * @param  {Action} action
     * @return {undefined}
     */


    publish(action) {
      action.attributes.order.amongPublishedClientActions = this.actionCount + 1;
      Ember.set(this, "actionCount", this.actionCount + 1);

      if (action.attributes.sync.isUserFacing) {
        Ember.set(this, "userFacingActionCount", this.userFacingActionCount + 1);
      }

      action.attributes.order.followsPublishedActionId = this.lastActionId;
      action.attributes.timing.clientSentAt = dateFns.format(new Date());
      this.lastActionId = action.id;

      this._publishNewAction(action);
    }

    _publishNewAction(action) {
      if (action === undefined) return; // If we've encounterd a fatal error, we need to not keep sending things.

      if (_environment.default.environment === "test") return;
      if (Ember.get(this, "hasEncounteredFatalError")) return;
      if (this.fastboot.isFastBoot) return;
      if (action.attributes.sync.status === "persisted") return;
      if (action.attributes.sync.status === "in-flight") return; //  if (get(action, "attributes.sync.failureCount") > 5) return;
      // Filter out card-stack-summary patches from being sent

      let patches = _lodash.default.reject(action.attributes.patches, patch => patch.document.modelType == "card-stack-summary");

      action.attributes.patches = patches;
      Ember.get(this, "actions").addObject(action);
      Ember.set(action.attributes.sync, "status", "in-flight");
      Ember.set(action.attributes.sync, "attemptCount", action.attributes.sync.attemptCount + 1);
      console.log("📬 Action Publishing", action.attributes.name, action.id);

      this._ensureChannelIsConnected();

      this.channel.push("new-action", action, 10000).receive("ok", ({
        unpersistedHistoricAction
      }) => {
        Ember.set(action.attributes.sync, "status", "persisted");
        Ember.get(this, "actions").removeObject(action);
        console.log(`📪 Action #${action.attributes.order.amongPublishedClientActions} Published!`, action.attributes.name, action.id);

        if (action.attributes.sync.isUserFacing) {
          Ember.set(this, "syncedUserFacingActionsCount", Ember.get(this, "syncedUserFacingActionsCount") + 1);
          Ember.get(this, "recentlyPublishedActions").pushObject(action);
        } // Keep it to 5 items. Not that reliable as we could have added more than one object.


        if (this.recentlyPublishedActions.length > 5) Ember.get(this, "recentlyPublishedActions").shift();

        if (unpersistedHistoricAction === true) {
          console.log("HISTORIC ACTION", action.attributes.name, action.id);
          let error = new Error(`HISTORIC ACTION Id: ${action.id}.`);
          (0, _app.notifySentry)(error, action.id);
        } // if there are actions in the queue, we send the next action. Most likely, it will already have
        // been sent and thus will be set to `in-flight` and won't double send.


        if (this.actions.length > 0) this._publishNewAction(this.actions[0]);
      }).receive("error", refusal => {
        Sentry.setTag("actionId", action.id);
        console.log("😒 Action failed", action.attributes.name, action.id, refusal); // Handle refusal reason == unmatched topic.

        if ("reason" in refusal) {
          // The only error we handle is "unmatched topic". If we handle others, we need a type check
          this.reloadPage();
          return;
        } else {
          // If we're getting an error from the server that hey, it just didn't broadcast
          if (refusal.code === "PUBSUB_BROADCAST_ERROR") {
            this._retryAction(action, refusal.code);

            return; // just retry
          } else if (refusal.code === "DB_CONNECTION_ERROR") {
            this._retryAction(action, refusal.code);

            return; // if we need a missing action
          } else if (refusal.code === "OUT_OF_ORDER") {
            this._retryAction(action, refusal.code); // HANDLE NACK
            // An alternative is to send over all actions with a higher count, but I think that would just lead to a higher load on the server


            (0, _lodashEs.forEach)((0, _lodashEs.filter)(this.actions, anAction => {
              var _refusal$meta;

              return anAction.attributes.order.amongPublishedClientActions === ((_refusal$meta = refusal.meta) === null || _refusal$meta === void 0 ? void 0 : _refusal$meta.actionNumberNeeded);
            }), anAction => this._publishNewAction(anAction)); // else, let's throw an error
          } else if (refusal.code === "CONFLICT") {
            this._failPermanently(action, refusal, refusal.code);
          } else {
            this._failPermanently(action, refusal, "OTHER");
          }
        }
      }).receive("timeout", () => {
        console.log("😒 Action timeout", action.attributes.name, action.id);
        Ember.set(action.attributes.sync, "status", "failed");
        Ember.set(action.attributes.sync, "failureReason", "timeout");
        Ember.set(action.attributes.sync, "retryAt", dateFns.addMilliseconds(new Date(), fibonacciBackoff(action.attributes.sync.attemptCount + 1, 500), 60000));
        console.log("Publish Action Timeout", ...arguments);
      });
    }

    _ensureChannelIsConnected() {
      if (this.channel.isClosed() === true) {
        this._connectToChannel();
      }
    }
    /* ==============================================================
     * Other
     * ==============================================================
     */

    /**
     * We set this so that we know not to check for unsynced actions
     */


    beginRedirect() {
      this.isRedirectingAfterSignIn = true;
    }
    /* ==============================================================
     * Private
     * ==============================================================
     */


    _retryAction(action, reason) {
      let retryMilliseconds = fibonacciBackoff(action.attributes.sync.attemptCount + 1, 1000, 60000);
      Ember.set(action.attributes.sync, "status", "failed");
      Ember.set(action.attributes.sync, "failureReason", reason);
      Ember.set(action.attributes.sync, "retryAt", dateFns.addMilliseconds(new Date(), retryMilliseconds));
    }

    _failPermanently(action, refusal, code) {
      let eventName = code === "CONFLICT" ? "Conflict Error" : "Server Error";

      if (window && window.analytics) {
        window.analytics.track(eventName, {
          errorId: refusal.id
        });
      }

      let failureCount = action.attributes.sync.failureCount || 0;
      let error = new Error(`${eventName} Id: ${refusal.id}.`);
      Sentry.configureScope(scope => {
        scope.setExtra("serverErrorId", refusal.id);
        scope.setExtra("refusal", refusal);
      });

      let id = (refusal === null || refusal === void 0 ? void 0 : refusal.id) || _objectIdGen.default.create();

      (0, _app.postError)(error, id);
      (0, _app.notifySentry)(error, id);
      Ember.set(action.attributes.sync, "status", "failed");
      Ember.set(action.attributes.sync, "failureReason", code);
      Ember.set(action.attributes.sync, "failureCount", failureCount + 1);
      let title = code === "CONFLICT" ? "The server just received two changes to the same thing at the same time." : "There's been a server error. We need to refresh your browser.";
      let description = code === "CONFLICT" ? `Error code: ${code}:${refusal.id}` : `We've been notified. Error code: ${code}:${refusal.id}`;
      swal({
        title: title,
        text: description,
        type: "warning",
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then(() => window.location.reload()).catch(() => window.location.reload());
      Ember.set(this, "hasEncounteredFatalError", true);
    }

    _pollActions() {
      this._pollActionsTimer = Ember.run.later(() => {
        _lodash.default.chain(Ember.get(this, "actions")).filter(action => {
          // if it's failed or it's been attempted once and it's over 15 seconds ago. This protects the case the channel
          // never times it out.
          return action.attributes.sync.status === "failed" || action.attributes.attemptCount === 1 && new Date().valueOf() - dateFns.parse(action.attributes.timing.clientSentAt) > 15000;
        }).filter(action => {
          if (Ember.get(this, "showBusySyncingModal")) {
            return true;
          } else if (action.attributes.sync.retryAt) {
            return action.attributes.sync.retryAt <= new Date();
          } else {
            return true;
          }
        }).sortBy("attributes.order.amongPublishedClientActions").take(1).forEach(action => {
          this._publishNewAction(action);
        }).value();

        this._pollActions();
      }, 1000);
    }
    /**
     * Admin
     */


    adminReloadSession({
      sessionId
    }) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._ensureChannelIsConnected();

        this.channel.push("admin-reload-session", {
          sessionId
        }).receive("ok", resolve).receive("error", reject);
      });
    }

    adminReloadAllSessions({
      userId
    }) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._ensureChannelIsConnected();

        this.channel.push("admin-reload-all-sessions", {
          userId
        }).receive("ok", resolve).receive("error", reject);
      });
    }

    adminSignOutAllSessions({
      userId
    }) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this._ensureChannelIsConnected();

        this.channel.push("admin-sign-out-all-sessions", {
          userId
        }).receive("ok", resolve).receive("error", reject);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "finder", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "rpc", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "socketSubscriber", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fastboot", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "inAppNotice", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isOnline", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "windowIsUnloading", [_trackedBuiltIns.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "reconnectToSocket", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "reconnectToSocket"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_notifyIsSyncing", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "_notifyIsSyncing"), _class.prototype)), _class));
  _exports.default = SocketService;

  // https://gist.github.com/kitcambridge/11101250
  function fibonacciBackoff(attempt, delay, maxWait = 10000) {
    var current = 1;

    if (attempt > current) {
      var prev = 1;
      current = 2;

      for (var index = 2; index < attempt; index++) {
        var next = prev + current;
        prev = current;
        current = next;
      }
    }

    return Math.min(maxWait, Math.floor(current * delay));
  } // I think we get this from Phoenix, but I can't quite tell who is sending it.
  // I think it's somewhere in the Phoenix Channel library

});