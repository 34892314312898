define("cc-frontend/utils/course/times", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCourseTimePart = formatCourseTimePart;
  _exports.parseCourseTimePart = parseCourseTimePart;
  _exports.formatCourseTime = formatCourseTime;
  _exports.parseCourseTime = parseCourseTime;
  _exports.addCourseTimeParts = addCourseTimeParts;

  function formatCourseTimePart(startOrEnd) {
    const partM = (0, _moment.default)().startOf("day").add(startOrEnd, "minutes");
    return partM.format("h:mma");
  }
  /**
   * Returns null if invalid.
   */


  function parseCourseTimePart(startOrEndInputStr) {
    const partM = (0, _moment.default)(startOrEndInputStr.trim(), ["LT"]);
    if (!partM.isValid()) return null;
    return 60 * partM.hours() + partM.minutes();
  }

  function formatCourseTime(time) {
    if (time === null) return "";
    return `${formatCourseTimePart(time.start)} - ${formatCourseTimePart(time.end)}`;
  }
  /**
   * If the string is empty, returns [true, null], assuming the user intended to clear the time.
   * You can choose to treat that case as invalid.
   */


  function parseCourseTime(timeInputStr) {
    timeInputStr = timeInputStr.trim();
    if (timeInputStr === "") return [true, null];
    const times = timeInputStr.split("-");
    if (times.length !== 2) return [false, null];
    const [startStr, endStr] = times;
    const start = parseCourseTimePart(startStr);
    const end = parseCourseTimePart(endStr);
    if (start === null || end === null) return [false, null];
    return [true, {
      start,
      end
    }];
  }

  function addCourseTimeParts(timePart, deltaMinutes) {
    const dayLength = 24 * 60; // Positive modulus (range [0, dayLength))

    return ((timePart + deltaMinutes) % dayLength + dayLength) % dayLength;
  }
});