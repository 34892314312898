define("cc-frontend/lib/section-moving-v5", ["exports", "cc-frontend/app", "ember-sortable/utils/manager"], function (_exports, _app, _manager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hideSectionValues = hideSectionValues;
  _exports.startSorting = startSorting;
  _exports.completeSorting = completeSorting;

  function hideSectionValues() {
    $(".week-grid").css({
      visibility: "hidden"
    }); // Setting the max-height instead of display: none seems to reduce time spend in layout

    $(`#lesson-section-${Ember.get(this, "model.id")}`).parents(".js-draggable-z-index-parent").css({
      "z-index": 999999,
      position: "relative"
    });
    $("body").append(`
    <style type="text/css" class="-moving-section-style" id="section-style-${Ember.get(this, "model.id")}">
      .lesson-section__body,
      .js-lesson-section__body {
        // visibility: hidden;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
      }
      .js-lesson-card-wrapper{
        padding: .5rem .5rem;
      }
      .js-lesson-card{
        border-bottom: 1px solid #b8c2cc;
        padding-top: .5rem;
        padding-bottom: .5rem;
      }
      .js-lesson-card:not(.is-dragging) > .js-card-top-part {
      }
      .lesson-sections__btn-add-lesson-card-to-bottom-wrapper,
      .lesson-sections__btn-add-lesson-card-to-bottom,
      .js-add-card-to-lesson-wrapper,
      .js-add-card-to-lesson{
        padding-top: 0;
        padding-bottom: 0;
        // visibility: hidden;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
      }
    </style>
  `);
    $(".week-grid").css("visibility", "visible");
    $("body").addClass("is-dragging");
  }
  /**
   *
   * @param {MouseEvent} originalEvent
   */


  function startSorting(originalEvent) {
    this.dragging.set("isDragging", true);
    this.beginMove(); // this._sortableManager = null

    let startingMouseUpCount = this.mouseUpCount; // $("body").animate({scrollTop: (top - clientY + 20)}, 500)
    // We wait 150 ms to see if we've let the mouse up

    Ember.run.later(() => {
      // return if destroyed
      if (this.isDestroyed || this.isDestroying) return; // return if we left the mouse up

      if (startingMouseUpCount !== this.mouseUpCount) {
        this.dragging.set("isDragging", false);
        return;
      } // We have to hide the sections and then scroll to the new position


      if ($("body").hasClass("is-mobile") === false) this.hideSectionValues();
      $("body").addClass("is-dragging");
      let {
        top,
        left
      } = this.$().offset();
      let clientY = originalEvent.clientY;
      originalEvent.pageX = left;
      originalEvent.pageY = top;
      $(window).scrollTop(top - clientY + 20); // We then give the browser a frame to catch up and we then measure
      // which the sortabl emanager does

      Ember.run.later(() => {
        if (this.isDestroyed || this.isDestroying) return;
        this._sortableManager = new _manager.default({
          node: this,
          onComplete: (...args) => {
            try {
              this.completeSorting(...args);
            } catch (e) {
              (0, _app.handleError)(e);
            }
          },
          onCancel: (...args) => {
            try {
              this.completeSorting(...args);
            } catch (e) {
              (0, _app.handleError)(e);
            }
          }
        });

        this._sortableManager.start(originalEvent); // enable the dragging before the mouse moves


        let wrapper = originalEvent.target.closest(".js-lesson-card-wrapper");
        if (wrapper) wrapper.classList.add("sortable-dragging");
      }, 15);
    }, 150);
  }

  function completeSorting(receiver, position) {
    // Take care of reshowing sections and such
    this.dragging.set("isDragging", false);
    cleanUpAndShowSections(); // if we don't have a receiver or a card, we just return so we don't error

    if (this === undefined || receiver === undefined) {
      return;
    } // If we call _super earlier, we lose access to _sortableManager


    this._super(...arguments);

    let offset = this._sortableManager === undefined ? 0 : this._sortableManager.gesture.clientY;
    let id = Ember.get(this, "model.id");
    let model = this.sortableModel;
    let oldParent = this.sortableParent.sortableModel;
    let newParent = receiver.sortableModel;
    let receiverContext = receiver.sortableContext;
    this.onMove(model, {
      oldParent,
      newParent,
      position,
      receiverContext
    }); // Since we've uncollapsed the items, we need to scroll to it.

    scrollToMovedItem(id, offset);
  }

  function cleanUpAndShowSections() {
    $(`.-moving-section-style`).remove();
    $(`.sortable-dragging`).removeClass("sortable-dragging");
    $("body").removeClass("is-dragging");
  }

  function scrollToMovedItem(id, offset) {
    Ember.run.scheduleOnce("afterRender", () => {
      Ember.run.later(() => {
        let $lessonSectionEl = $(`#lesson-section-${id}`);

        if ($lessonSectionEl) {
          let lessonSectionOffset = $lessonSectionEl.offset();

          if (lessonSectionOffset) {
            $(window).scrollTop(lessonSectionOffset.top - offset + 20); // 25 is so the cursor is at the card title
          } else {// Happens if we're dragging to a new lesson that triggers LESSON_FIRST_EDIT
            }
        }

        requestAnimationFrame(() => {
          $(".week-grid").css({
            visibility: "visible",
            opacity: 1
          });
        });
      }, 0);
    });
  }
});