define("cc-frontend/components/planner/menus/unit-menu/primary/body", ["exports", "@glimmer/component", "cc-frontend/app", "cc-frontend/lib/actions/lesson/LESSON_COPY", "lodash-es"], function (_exports, _component, _app, _LESSON_COPY, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="tw-mt-4"></div>
  <DS::DropdownMenu::SingleButtonRow @iconName="icons/copy-doc" @clickAction={{this.copyUnit}}>
    Copy
  </DS::DropdownMenu::SingleButtonRow>
  <DS::DropdownMenu::SingleButtonRow @iconName="icons/paste-doc" @clickAction={{this.pasteUnit}}>
    Paste
  </DS::DropdownMenu::SingleButtonRow>
  <DS::DropdownMenu::SingleButtonRow @iconName="icons/trash-can" @clickAction={{this.eraseUnit}}>
    Erase
  </DS::DropdownMenu::SingleButtonRow>
  <DS::DropdownMenu::SingleButtonRow
    @iconName="icons/rotate-back"
    @clickAction={{this.viewVersionHistory}}
  >
    Unit History
  </DS::DropdownMenu::SingleButtonRow>
  {{! Not sure if this will be tricky or not - we just need a date (probs a daterange) }}
  {{!-- <Planner::Menus::Parts::ShareLink
    @planbook={{@planbook}}
    @date={{@currentYearMonth}}
    @perspective="unit"
    @closeMenu={{@closeMenu}}
  >
    <DS::DropdownMenu::IconRow::Item @iconName="icons/link-simple" @clickAction={{this.shareAsLink}}>
      Link
    </DS::DropdownMenu::IconRow::Item>
  </Planner::Menus::Parts::ShareLink> --}}
  
  <DS::DropdownMenu::SingleButtonRow
    @iconName="icons/print-text"
    @clickAction={{this.printOrDownload}}
  >
    Print or Download
  </DS::DropdownMenu::SingleButtonRow>
  <div class="g-dropdown-menu__single-button-row-divider"></div>
  <DS::DropdownMenu::SingleButtonRow
    @iconName="icons/lightning-bolt"
    @clickAction={{@goToReplaceTemplate}}
  >
    Replace with Template
  </DS::DropdownMenu::SingleButtonRow>
  <DS::DropdownMenu::SingleButtonRow
    @iconName="icons/lightning-bolt-cloud"
    @clickAction={{@saveUnitAsTemplate}}
  >
    Save as Template
  </DS::DropdownMenu::SingleButtonRow>
  {{!--
        <div class="g-dropdown-menu__single-button-row-divider"></div>
  
        <DS::DropdownMenu::SingleButtonRow @isSecondary=true @clickAction={{this.editCourse}}>
          Edit Class Title, Color, & Order
        </DS::DropdownMenu::SingleButtonRow>
      --}}
  */
  {
    "id": "ZClNqjc1",
    "block": "[[[10,0],[14,0,\"tw-mt-4\"],[12],[13],[1,\"\\n\"],[8,[39,0],null,[[\"@iconName\",\"@clickAction\"],[\"icons/copy-doc\",[30,0,[\"copyUnit\"]]]],[[\"default\"],[[[[1,\"\\n  Copy\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,0],null,[[\"@iconName\",\"@clickAction\"],[\"icons/paste-doc\",[30,0,[\"pasteUnit\"]]]],[[\"default\"],[[[[1,\"\\n  Paste\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,0],null,[[\"@iconName\",\"@clickAction\"],[\"icons/trash-can\",[30,0,[\"eraseUnit\"]]]],[[\"default\"],[[[[1,\"\\n  Erase\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,0],null,[[\"@iconName\",\"@clickAction\"],[\"icons/rotate-back\",[30,0,[\"viewVersionHistory\"]]]],[[\"default\"],[[[[1,\"\\n  Unit History\\n\"]],[]]]]],[1,\"\\n\"],[1,\"\\n\"],[8,[39,0],null,[[\"@iconName\",\"@clickAction\"],[\"icons/print-text\",[30,0,[\"printOrDownload\"]]]],[[\"default\"],[[[[1,\"\\n  Print or Download\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"g-dropdown-menu__single-button-row-divider\"],[12],[13],[1,\"\\n\"],[8,[39,0],null,[[\"@iconName\",\"@clickAction\"],[\"icons/lightning-bolt\",[30,1]]],[[\"default\"],[[[[1,\"\\n  Replace with Template\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,0],null,[[\"@iconName\",\"@clickAction\"],[\"icons/lightning-bolt-cloud\",[30,2]]],[[\"default\"],[[[[1,\"\\n  Save as Template\\n\"]],[]]]]],[1,\"\\n\"]],[\"@goToReplaceTemplate\",\"@saveUnitAsTemplate\"],false,[\"d-s/dropdown-menu/single-button-row\"]]",
    "moduleName": "cc-frontend/components/planner/menus/unit-menu/primary/body.hbs",
    "isStrictMode": false
  });

  let UnitMenu = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class UnitMenu extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "modalManager", _descriptor, this);

      _initializerDefineProperty(this, "dialog", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "lessonEditor", _descriptor5, this);

      _initializerDefineProperty(this, "appState", _descriptor6, this);

      _initializerDefineProperty(this, "planbookEditor", _descriptor7, this);
    }

    eraseUnit() {
      this.args.closeMenu();
      this.dialog.blank("dialogs/confirm-delete-unit", {
        className: "dialog--create-annotation"
      }).then(shouldDeleteLessons => {
        this.lessonEditor.eraseUnit(this.args.unitCardStack.id, this.args.course, this.args.planbook, this.args.rotationCalendar, shouldDeleteLessons);
        this.args.closeMenu();
        this.modalManager.closeModal("planner/course/timeline/sidebar");
        this.modalManager.closeModal("planner/modals/unit-comparison-modal");
      }).catch(error => {
        if (error instanceof Error) (0, _app.handleError)(error);
      });
    }

    copyUnit() {
      if (this.session.isAuthenticated !== true) {
        this.session.mustSignInToProceed("Copy Lesson");
        return;
      }

      if (this.session.userId === null) return;
      this.store.dispatchPreparedAction((0, _LESSON_COPY.prepareLessonCopy)({
        lessonToCopy: this.args.unitCardStack,
        cardStackId: this.args.unitCardStack.id,
        currentUserId: this.session.userId,
        ownerIds: [this.args.planbook.relationships.owner.data.id],
        planbookId: this.args.planbook.id,
        courseId: this.args.course.id
      }));
      this.args.closeMenu();
    }

    pasteUnit() {
      if (!(0, _lodashEs.isNil)(this.session.userId)) {
        let currentUser = this.store.find("user", this.session.userId).then(user => {
          var _user$attributes, _user$attributes$clip;

          if ((0, _lodashEs.isNil)(user === null || user === void 0 ? void 0 : (_user$attributes = user.attributes) === null || _user$attributes === void 0 ? void 0 : (_user$attributes$clip = _user$attributes.clipboard) === null || _user$attributes$clip === void 0 ? void 0 : _user$attributes$clip.cardStackAttributes)) return;
          let newCardStackAttributes = (0, _lodashEs.cloneDeep)(user.attributes.clipboard.cardStackAttributes);
          if (Ember.isNone(newCardStackAttributes)) return;
          this.lessonEditor.pasteCardStack(this.args.modelsNeededForFirstEdit, this.args.unitCardStack.id, newCardStackAttributes, this.args.unitCardStack.attributes, "unit", newCardStackAttributes.parentCardStack);
        });
      }

      this.args.closeMenu();
    }

    viewVersionHistory() {
      this.modalManager.openModal("planner/modals/lesson-history", {
        cardStack: this.args.unitCardStack
      });
      this.args.closeMenu();
    }

    printOrDownload() {
      this.modalManager.openModal("planner/modals/print-download", {
        courseId: this.args.course.id,
        unitId: this.args.unitCardStack.id,
        startDate: this.args.unitInfo.startDate,
        endDate: this.args.unitInfo.endDate,
        layout: "course-unit",
        canPrint: true
      });
      this.args.closeMenu();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modalManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "lessonEditor", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "appState", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "planbookEditor", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "eraseUnit", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "eraseUnit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyUnit", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "copyUnit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pasteUnit", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "pasteUnit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewVersionHistory", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "viewVersionHistory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "printOrDownload", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "printOrDownload"), _class.prototype)), _class));
  _exports.default = UnitMenu;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UnitMenu);
});